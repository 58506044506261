import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Handicaps = () => (
  <HowToLayout>
    <h2>Handicaps</h2>
    <section className="documentation-section">
      <p>
        4BAGR has a built in automatic handicapping system. The way it works is
        simple, handicaps are automatically re-calculated after every score
        submission based on average score - e.g: if on average a team scores 17
        points per game, their handicap will be 4 (17 subtracted from 21).
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="set-up-handicaps">Setting up Handicaps</h3>
      <p>
        Setting your league to make use of handicaps is quite simple when
        setting up your league or editing it:
      </p>
      <ol>
        <li>
          Navigate to the league settings screen (Create league Screen if this
          is your initial setup)
        </li>
        <li>
          Toward the bottom of the settings, there's a button to 'Allow
          Handicaps' - Make sure it's selected
        </li>
        <li>
          You'll now see a setting to 'Cap' the handicap at a maximum amount.
          Handicaps for your league will not go higher than the amount that you
          set this at.
        </li>
        <li>Click 'Update Settings'</li>
      </ol>
      <p>
        Now that handicaps are implemented, you can generate your schedule and
        begin league. Handicaps will automatically show up in the standings and
        on match score cards.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="how-to">How to use handicaps</h3>
      <p>
        The Handicaps are merely a suggestion and can be used any way the
        organizer wishes. Normally with the 4BAGR handicapping system the team
        with the higher handicap starts out their game with the difference in
        handicap on the scoreboard. e.g: Team 1 has a handicap of 6 and Team 2
        has a handicap of 2. Team 1 will start each game of the match with 4
        points and Team 2 will start with 0.
      </p>
      <p>
        The recommended strategy for setting up handicaps is to run the first
        week of league as if everyone has a handicap of 0 (even though the
        handicaps will be calculated as soon as scores are submitted). This will
        help the handicaps get settled in to a fair number before using them.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="editing-handicaps">Editing Handicaps</h3>
      <p>
        There's times where the organizer might feel a need to edit a handicap
        for a team or 'Lock' a teams handicap so that it's no longer
        automatically updated. This can also be used when starting a new league
        and you'd like to keep teams at the handicap that they had from the
        previous league. The process is simple:
      </p>
      <ol>
        <li>Navigate to the 'Standings' tab within the league</li>
        <li>Select the team you'd like to update from the standings</li>
        <li>From here you can set their handicap or lock it</li>
      </ol>
      <p>
        Locking a handicap means that team's handicap will no longer update
        automatically and be 'Locked' into place. If you lock a team's handicap
        at 5, they will always have a 5 handicap until you unlock it or update
        it manually. If you update a handicap but do not lock it, that team will
        have that handicap until their next score is submitted and it will be
        re-calculated as usual.
      </p>
    </section>
  </HowToLayout>
);

export default Handicaps;
